import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import * as packageJson from '../package.json';
import Playground from '../../../src/Playground';
import { UiText } from '@uireact/text';
import { UiProgressIndicator, UiProgressIndicatorItem } from '../src/';
import { ProgressIndicatorExample } from './utils';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">







    <h1 {...{
      "id": "uiprogressindicator"
    }}>{`UiProgressIndicator`}</h1>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/progress-indicator/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`Used to represent the progress in a multi step process`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation @uireact/progress-indicator`}</p>
    </blockquote>
    <h2 {...{
      "id": "uiprogressindicator-1"
    }}>{`UiProgressIndicator`}</h2>
    <Playground __position={1} __code={'<UiProgressIndicator>\n  <UiProgressIndicatorItem>\n    <UiText>Step 1</UiText>\n  </UiProgressIndicatorItem>\n  <UiProgressIndicatorItem>\n    <UiText>Step 2</UiText>\n  </UiProgressIndicatorItem>\n  <UiProgressIndicatorItem>\n    <UiText>Step 3</UiText>\n  </UiProgressIndicatorItem>\n</UiProgressIndicator>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiText,
      UiProgressIndicator,
      UiProgressIndicatorItem,
      ProgressIndicatorExample,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiProgressIndicator mdxType="UiProgressIndicator">
    <UiProgressIndicatorItem mdxType="UiProgressIndicatorItem">
      <UiText mdxType="UiText">Step 1</UiText>
    </UiProgressIndicatorItem>
    <UiProgressIndicatorItem mdxType="UiProgressIndicatorItem">
      <UiText mdxType="UiText">Step 2</UiText>
    </UiProgressIndicatorItem>
    <UiProgressIndicatorItem mdxType="UiProgressIndicatorItem">
      <UiText mdxType="UiText">Step 3</UiText>
    </UiProgressIndicatorItem>
  </UiProgressIndicator>
    </Playground>
    <h2 {...{
      "id": "uiprogressindicator-example-using-allowgoback"
    }}>{`UiProgressIndicator example using allowGoBack`}</h2>
    <Playground __position={2} __code={'<ProgressIndicatorExample />'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiText,
      UiProgressIndicator,
      UiProgressIndicatorItem,
      ProgressIndicatorExample,
      DefaultLayout,
      _frontmatter
    }} hideThemeSelector mdxType="Playground">
  <ProgressIndicatorExample mdxType="ProgressIndicatorExample" />
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <Props of={UiProgressIndicator} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      